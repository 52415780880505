<template>
    <div>
      <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Onboard Approval" :detailID="ApproveDriverDetails.employee_no"/>
        <v-spacer></v-spacer>
        <v-btn class="mx-1 px-8" depressed dark color="primary" v-show="savingState">
            <v-progress-circular
              :size="20"
              color="white"
              indeterminate
              class="mr-3"
            ></v-progress-circular>Saving
        </v-btn>
        <v-btn 
          v-if="canDo && canDo.can.includes('update')"
          class="mx-1 px-8" depressed dark color="primary" v-show="!savingState" @click="saveDriver">Save</v-btn>
        <v-btn class="mx-1 px-8" 
          v-if="canDo && canDo.can.includes('delete_rider')"
          depressed dark color="warning" v-show="!savingState" @click="deleteDriverConfirm">Delete</v-btn>
        <v-btn class="mx-1 px-8" depressed dark color="grey" @click="()=>$router.push('/approve_onboard')">Back</v-btn>
      </v-toolbar>  
      <v-divider></v-divider>
      <!-- Content -->
      <div class="content-wrap">
          <!-- <v-container> -->
            <v-card
              class="mx-auto d-flex pa-2 px-10 justify-center"
              max-width="90%"
              flat
            >
            <v-form v-model="form_valid" ref="form">
              <v-row>
                <v-col cols="3">
                  <ProfileImage/>
                </v-col>
                <v-col cols="9">
                  <v-row>
                    <ApproveStatus :approve_status="approve_status"/>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="driver.name" :rules="[validate_rules.required]" label="Full name *" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="driver.email" :rules="[validate_rules.email]" label="Email"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="driver.phone" :rules="[validate_rules.phone]" label="Phone No. *" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="driver.idcard" :rules="[validate_rules.idcard]" label="ID Card No. / Passport No. *"  required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-combobox
                        :items="allProvinces"
                        label="Province *"
                        v-model="driver.province"
                        :rules="[validate_rules.required]"
                        dense
                        @change="fetchZones()"
                        clearable
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-combobox
                        :items="allZones"
                        label="District *"
                        :search-input.sync="selectedZone"
                        :rules="[validate_rules.required]"
                        v-model="driver.selected_site"
                        item-value="value"
                        item-text="text"
                        @click="ifProvSelected()"
                        dense
                        clearable
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-autocomplete
                        :items="rb_stores"
                        label="Store"

                        :disabled="!this.canDo.can.includes('update_store_tier')"
                        v-model="driver.store_uid"
                        item-value="store_uid"
                        :item-text="storeName"
                        :rules="[validate_rules.select]"
                        dense
                        >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <!-- <v-text-field v-model="driver.training_date" label="Training Date *" required></v-text-field> -->
                      <v-select
                        :items="trainingOptionList"
                        label="Training *"
                        v-model="driver.training_id"
                        item-value="id"
                        item-text="value"
                        :rules="[validate_rules.training]"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        :items="depositStatusOptions"
                        label="Deposit Status *"
                        v-model="driver.deposit_status"
                        item-value="id"
                        item-text="text"
                        :rules="[validate_rules.select]"
                        >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="categoriesArray"
                        v-model="driver.categories"
                        attach
                        dense
                        :rules="[validate_rules.required]"
                        small-chips
                        deletable-chips
                        label="Category Tags"
                        hide-selected
                        multiple
                        clearable
                      ></v-autocomplete>
                    </v-col> 
                    <!--
                    <v-col cols="12" sm="12">
                      <p>Equipment</p>
                      <v-container class="grey lighten-5">
                        <v-row>
                          <v-col col="4" sm="4" class="pt-0" v-for="eq in equipmentList" :key="eq.id">
                            <v-checkbox
                              v-model="driver.equipments"
                              :label="eq.text"
                              :value="eq.id"
                              hide-details
                              class="ma-0"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                    -->
                  </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                  <small class="red--text">* indicates required field</small>
                  </v-col>
                </v-row>
                </v-col>
              </v-row>
            </v-form>
            </v-card>
            <v-card
              class="mx-auto d-flex py-3 px-10 justify-center"
              max-width="90%"
              flat
            >
              <Evidences :driverid="ApproveDriverDetails.id"/>
            </v-card>
          <!-- </v-container> -->
      </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import ProfileImage from '@/components/drivers/profile_image.vue';
import Evidences from '@/components/drivers/evidences.vue';
import ApproveStatus from '@/components/drivers/approve_status.vue';
import lodash from 'lodash';

export default {
  name: 'OnboardApproval',
  data: () => ({
      driver: [],
      approve_status: '',
      rb_stores: [],
      form_valid : false,
      validate_rules : {
        required: v => !!v || 'This field is required',
        select2: v => {
          if(typeof v === 'object'){
            return (v.value !== '') || 'This field is required';
          } else {
            return true
          }
        },
        training: v => { return (v !== '') || 'This field is required' },
        select: v => { return (v !== '') || 'This field is required' },
        phone: v => (/^\d{10}$/.test(v) || 'Required 10 digits phone number'),
        idcard: v => (/[a-zA-Z0-9]{10,13}/.test(v) || 'Data pattern is invalid'),
        email: v => { 
          if(v) { 
            return (/.+@.+\..+/.test(v) || 'Valid email pattern is required');
          } else {
            return true
          } 
        },
      }
  }),
  components: {
      pageHeading,
      ProfileImage,
      Evidences,
      ApproveStatus
  },
  methods: {
    ...mapActions(['genCan','deleteDriver','resetProfileImage','approving','getApproveDriverDetails','getTrainings','getProvinces','getZones','clearZones',
    'updateApproveDriver','getEquipments','getCategories', 'getAllRedbookStore', 'getTPCTierId']),
    storeName: item => item.store_alias_id + " " + item.name,
    initialize() {
      
      this.driver = {
        id: this.ApproveDriverDetails.id,
        name : this.ApproveDriverDetails.name_th,
        email : this.ApproveDriverDetails.email,
        phone : this.ApproveDriverDetails.phone,
        idcard : this.ApproveDriverDetails.ssn,
        province: null,
        zone: this.ApproveDriverDetails.site_id,
        store_uid: this.ApproveDriverDetails.store_uid,
        selected_site : {
          value: this.ApproveDriverDetails.site_id,
          text: this.ApproveDriverDetails.site_name,
        },
        categories: this.ApproveDriverDetails.employee_category_tag,
        training_id: this.ApproveDriverDetails.employee_training[0].training_schedule.id,
        deposit_status: this.ApproveDriverDetails.deposit_status,
        equipments: this.ApproveDriverDetails.equipments
        // training_date: this.ApproveDriverDetails.employee_training[0].training_schedule.training_date,
        // evd_images: this.ApproveDriverDetails.evd_images
      };
      this.approve_status = this.ApproveDriverDetails.employee_approve_step.name;
      if(this.ApproveDriverDetails.site_id){
        var prov_id = this.ApproveDriverDetails.site_id.substr(0,2);
        this.driver.province = {
          value: prov_id,
          text: this.ProvincesArray[prov_id]
        };
        this.getZones(this.driver.province.value);
      }
    },
    async saveDriver(){
      if(this.form_valid){
        if(this.driver.tier == (await this.getTPCTierId()) && !this.driver.store_uid)
        { 
          alert('กรุณาระบุค่า Store');
          return;
        }

        this.updateApproveDriver(this.driver);
      } else {
        alert('กรุณากรอกข้อมูลให้ถูกต้อง')
      }
    },
    deleteDriverConfirm(){
      if (confirm("Confirm to delete this employee?"))
      {
        this.deleteDriver({id : this.driver.id, redirect_uri : '/approve_onboard' });
      }
    },
    ifProvSelected(){
      if(!this.driver.province)
        alert('Please select a province filter');
    },
    fetchZones() {
      this.driver.zone = null;
      if(this.driver.province){
        this.getZones(this.driver.province.value);
      } else {
        this.clearZones();
      }
    }
  },
  computed: {
    ...mapGetters(['canDo','savingState','ApproveDriverDetails','trainingOptions','ProvincesArray','allProvinces','allZones',
    'depositStatus','equipmentList','categoriesArray','userProfile']),
    selectedZone: {
        get: function () {
          return this.driver.zone ? this.driver.zone.text : null;
        },
        set: function () {
          return this.driver.zone ? this.driver.zone.text : null;
        }
    },
    trainingOptionList () {
      return this.trainingOptions || null
    },
    zoneOptions() {
      return this.allZones
    },
    depositStatusOptions(){
      if(this.ApproveDriverDetails && this.ApproveDriverDetails.deposit_status == 0){
        return this.depositStatus.filter(element => (element.id != 2));
      } else {
        return this.depositStatus;
      }
    }
  },
  watch :{
    ApproveDriverDetails: function(){
      this.initialize();
    },
  },
  created() {
    this.genCan();
    this.getProvinces();
    this.resetProfileImage();
    this.approving('onboard');
    this.getApproveDriverDetails(this.$route.params.id);
    this.getTrainings('all');
    this.getEquipments();
    if(!this.categoriesArray){
      this.getCategories();
    }
    if (this.userProfile.store_uid)
    {
      this.rb_stores = lodash.filter(this.rb_stores, function (item) {
        return this.userProfile.store_uid.includes(item.store_uid);
      });
    }
  },
  async beforeMount() {
    this.rb_stores = await this.getAllRedbookStore();
  },
};
</script>